// assets
import {
    IconUsers,
    IconUser,
    IconHome,
    IconHomeQuestion,
    IconCurrencyDollar,
    IconChartInfographic,
    IconMessage,
    IconTool,
    IconAd2,
    IconSettings,
    IconDashboard,
    IconBriefcase,
    IconWriting,
    IconPaint,
    IconBuildingSkyscraper,
    IconMoneybag,
    IconFileDollar,
    IconHelp,
    IconFriends,
    IconUserCircle,
    IconHomeDollar,
    IconCalculator,
    IconDeviceAnalytics,
    IconSend,
    IconAddressBook,
    IconShieldCheck
} from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconUser,
    IconHome,
    IconHomeQuestion,
    IconCurrencyDollar,
    IconChartInfographic,
    IconMessage,
    IconTool,
    IconAd2,
    IconSettings,
    IconDashboard,
    IconBriefcase,
    IconWriting,
    IconPaint,
    IconBuildingSkyscraper,
    IconMoneybag,
    IconFileDollar,
    IconHelp,
    IconFriends,
    IconUserCircle,
    IconHomeDollar,
    IconCalculator,
    IconDeviceAnalytics,
    IconSend,
    IconAddressBook,
    IconShieldCheck
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    caption: 'Pages',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            icon: icons.IconDashboard,
            url: '/',
            target: false
        },
        {
            id: 'properties',
            title: 'Properties',
            type: 'item',
            icon: icons.IconHome,
            url: '/properties',
            target: false
        },
        {
            id: 'accounting',
            title: 'Payments',
            type: 'item',
            icon: icons.IconCurrencyDollar,
            url: '/accounting',
            target: false
        },
        {
            id: 'rnpl',
            title: 'Rent Now Pay Later',
            type: 'item',
            icon: icons.IconHomeDollar,
            url: '/safelease',
            target: false
        },
        {
            id: 'applications',
            title: 'Leads',
            type: 'item',
            icon: icons.IconHomeQuestion,
            url: '/applications',
            target: false
        },
        // {
        //     id: 'maintenace',
        //     title: 'Maintence',
        //     type: 'item',
        //     icon: icons.IconPaint,
        //     url: '/maintenance',
        //     target: false
        // },
        // {
        //     id: 'shieldplus',
        //     title: 'ShieldPlus',
        //     type: 'item',
        //     icon: icons.IconShieldCheck,
        //     url: '/shieldplus',
        //     target: false
        // },
        {
            id: 'listings',
            title: 'Listings',
            type: 'item',
            url: '/listings',
            icon: icons.IconAd2,
            target: false
        },
        {
            id: 'tenants',
            title: 'Tenants',
            type: 'item',
            icon: icons.IconUsers,
            url: '/tenants',
            target: false
        },
        {
            id: 'leases',
            title: 'Leases',
            type: 'item',
            icon: icons.IconWriting,
            url: '/leases',
            target: false
        },

        // {
        //     id: 'contacts',
        //     title: 'Contacts',
        //     type: 'item',
        //     url: '/contacts',
        //     icon: icons.IconAddressBook,
        //     target: false
        // },
        // {
        //     id: 'campaigns',
        //     title: 'Campaigns',
        //     type: 'item',
        //     url: '/campaigns',
        //     icon: icons.IconSend,
        //     target: false
        // },
        {
            id: 'team',
            title: 'Team',
            type: 'item',
            icon: icons.IconUsers,
            url: '/team',
            target: false
        }
        // {
        //     id: 'report',
        //     title: 'Reports',
        //     type: 'item',
        //     icon: icons.IconDeviceAnalytics,
        //     url: '/reports',
        //     target: false
        // }
    ]
};

export default pages;
