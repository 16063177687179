import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getSafeLeases = createAsyncThunk('SafeLease/getPaginatedSafeLease', async ({ reference_number }) => {
    try {
        let url = '';
        if (reference_number && page) {
            url = `/safelease/getSafeLease?ref_number=${reference_number}&page=${page}`;
        } else {
            url = `/safelease/getSafeLease?page=${page}`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getSafeLeasesNotPaginated = createAsyncThunk('SafeLease/getSafeLeases', async () => {
    try {
        const { data } = await api.get(`/safelease/getSafeLease`);
        return data;
    } catch (error) {}
});
export const getSafeLease = createAsyncThunk('SafeLease/getSafeLease', async ({ id }) => {
    try {
        const { data } = await api.get(`/safelease/getSafeLease?id=${id}`);
        return data;
    } catch (error) {}
});

export const updateSafeLeases = createAsyncThunk('SafeLease/updateSafeLease', async ({ id, status }) => {
    try {
        const { data } = await api.put(`/safelease/editSafeLease/${id}`, {
            id,
            status
        });
        return data;
    } catch (error) {}
});
