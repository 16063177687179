import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getSafeLeaseClaims = createAsyncThunk('SafeLeaseClaims/getPaginatedClaims', async ({}) => {
    try {
        let url = `/safeleaseclaim/getSafeLeaseClaim?page=${page}`;
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getSafeLeaseClaimNotPaginated = createAsyncThunk('SafeLeaseClaims/getClaimsNotPaginated', async () => {
    try {
        const { data } = await api.get(`/safeleaseclaim/getSafeLeaseClaim`);
        return data;
    } catch (error) {}
});
export const getSafeLeaseClaim = createAsyncThunk('SafeLeaseClaims/getSafeLeaseClaim', async ({ id }) => {
    try {
        const { data } = await api.get(`/safeleaseclaim/getSafeLeaseClaim?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteSafeLeaseClaim = createAsyncThunk('SafeLeaseClaims/deleteSafeLeaseClaim', async ({ id }) => {
    try {
        const { data } = await api.delete(`/safeleaseclaim/deleteSafeLeaseClaim/${id}`);
        return data;
    } catch (error) {}
});

export const addSafeLeaseClaim = createAsyncThunk('SafeLeaseClaims/addSafeLeaseClaim', async ({ formData }) => {
    try {
        const { data } = await api.post(`/safeleaseclaim/addSafeLeaseClaim`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {}
});

export const updateSafeLeaseClaim = createAsyncThunk('SafeLeaseClaims/updateSafeLeaseClaim', async ({ id, status }) => {
    try {
        const { data } = await api.patch(`/safeleaseclaim/editSafeLeaseClaim/${id}`, {
            id,
            status
        });
        return data;
    } catch (error) {}
});
