import { createSlice } from '@reduxjs/toolkit';
import {
    addSafeLeaseClaim,
    getSafeLeaseClaim,
    deleteSafeLeaseClaim,
    getSafeLeaseClaims,
    getSafeLeaseClaimNotPaginated,
    updateSafeLeaseClaim
} from './safeLeaseClaimsActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    SafeLeaseClaimLoading: false,
    SafeLeaseClaimSuccess: false,
    SafeLeaseClaimMsg: '',
    SafeLeaseClaims: [],
    SafeLeaseClaim: undefined
};

const SafeLeaseClaim = createSlice({
    name: 'SafeLeaseClaim',
    initialState,
    reducers: {
        clearSafeLeaseClaimMessages: (state) => {
            state.error = false;
            state.SafeLeaseClaimMsg = '';
            state.SafeLeaseClaimSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSafeLeaseClaims.pending, (state) => {
            state.SafeLeaseClaimLoading = true;
        });
        builder.addCase(getSafeLeaseClaims.fulfilled, (state, action) => {
            state.SafeLeaseClaimLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.SafeLeaseClaims = action.payload?.data.items;
            state.error = action.payload?.error;
            state.SafeLeaseClaimMsg = action.payload?.msg;
            state.SafeLeaseClaimSuccess = !action.payload?.error;
        });
        builder.addCase(getSafeLeaseClaim.pending, (state) => {
            state.SafeLeaseClaimLoading = true;
        });
        builder.addCase(getSafeLeaseClaim.fulfilled, (state, action) => {
            state.SafeLeaseClaimLoading = false;
            state.SafeLeaseClaim = action.payload?.data[0];
            state.error = action.payload?.error;
            state.SafeLeaseClaimMsg = action.payload?.msg;
            state.SafeLeaseClaimSuccess = !action.payload?.error;
        });
        builder.addCase(getSafeLeaseClaimNotPaginated.pending, (state) => {
            state.SafeLeaseClaimLoading = true;
        });
        builder.addCase(getSafeLeaseClaimNotPaginated.fulfilled, (state, action) => {
            state.SafeLeaseClaimLoading = false;
            state.SafeLeaseClaimSuccess = !action.payload?.error;
            state.SafeLeaseClaims = action.payload?.data;
            state.error = action.payload?.error;
            state.SafeLeaseClaimMsg = action.payload?.msg;
        });
        builder.addCase(addSafeLeaseClaim.pending, (state) => {
            state.SafeLeaseClaimLoading = true;
        });
        builder.addCase(addSafeLeaseClaim.fulfilled, (state, action) => {
            state.SafeLeaseClaimLoading = false;
            state.error = action.payload?.error;
            state.SafeLeaseClaimSuccess = !action.payload?.error;
            state.SafeLeaseClaimMsg = action.payload?.msg;
        });
        builder.addCase(updateSafeLeaseClaim.pending, (state) => {
            state.SafeLeaseClaimLoading = true;
        });
        builder.addCase(updateSafeLeaseClaim.fulfilled, (state, action) => {
            state.SafeLeaseClaimLoading = false;
            state.error = action.payload?.error;
            state.SafeLeaseClaimSuccess = !action.payload?.error;
            state.SafeLeaseClaimMsg = action.payload?.msg;
        });
        builder.addCase(deleteSafeLeaseClaim.fulfilled, (state, action) => {
            state.SafeLeaseClaimLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.SafeLeaseClaims = action.payload?.data.items;
            state.error = action.payload?.error;
            state.SafeLeaseClaimMsg = action.payload?.msg;
            state.SafeLeaseClaimSuccess = !action.payload?.error;
        });
    }
});

export const { clearSafeLeaseClaimMessages } = SafeLeaseClaim.actions;

export default SafeLeaseClaim.reducer;
