/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ApplicationsPage from 'views/pages/applications/index';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const AuthGuard = Loadable(lazy(() => import('views/utilities/authGuard')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// pages routing
const PropertyPage = Loadable(lazy(() => import('views/pages/properties/index')));
const PropertyForm = Loadable(lazy(() => import('components/property/propertyForm')));
const PropertyDetailsPage = Loadable(lazy(() => import('views/pages/properties/propertyDetails')));
const ReportsPage = Loadable(lazy(() => import('views/pages/reports/index')));
const AccountingPage = Loadable(lazy(() => import('views/pages/accounting/index')));
const TenantsPage = Loadable(lazy(() => import('components/people/tenants/adminTenantIndex')));
const TeamsPage = Loadable(lazy(() => import('components/people/team/team')));
const LeasePage = Loadable(lazy(() => import('views/pages/leasing/index')));
const LeaseDetails = Loadable(lazy(() => import('views/pages/leasing/leaseDetails')));
const LeaseForm = Loadable(lazy(() => import('views/pages/leasing/leaseForm')));
const ApplicationPage = Loadable(lazy(() => import('views/pages/applications/index')));
const ApplicationDetailsPage = Loadable(lazy(() => import('views/pages/applications/applicationDetails')));
const MaintenancePage = Loadable(lazy(() => import('views/pages/maintenance/index')));
const Maintenance = Loadable(lazy(() => import('components/maintenance/maintenance')));
const MaintenanceDetailsPage = Loadable(lazy(() => import('components/maintenance/maintenanceDetails')));
const MarketingPage = Loadable(lazy(() => import('views/pages/marketing/index')));
const ListingDetails = Loadable(lazy(() => import('components/marketings/listingsDetails')));
const ListingForm = Loadable(lazy(() => import('components/marketings/listingsForm')));
const CommunicationsPage = Loadable(lazy(() => import('views/pages/communications/index')));
const MessageForm = Loadable(lazy(() => import('components/communications/messageForm')));
const DocumentPage = Loadable(lazy(() => import('views/pages/documents/index')));
const SettingsPage = Loadable(lazy(() => import('views/pages/settings/index')));
const UnitsPage = Loadable(lazy(() => import('components/units/index')));
const UnitsForm = Loadable(lazy(() => import('components/units/unitsForm')));
const ProfilePage = Loadable(lazy(() => import('views/pages/authentication/account')));
const TenantForm = Loadable(lazy(() => import('components/people/tenants/tenantsForm')));
const UnitDetails = Loadable(lazy(() => import('components/units/unitDetails')));
const TenantsDetails = Loadable(lazy(() => import('components/people/tenants/tenantDetails')));
const UserForm = Loadable(lazy(() => import('components/auth/addUser')));
const HelpPage = Loadable(lazy(() => import('views/pages/help/index')));
const SettlementAccountsForm = Loadable(lazy(() => import('components/payments/settlement-accounts/settlement-account-form')));
const SettlementAccountsPage = Loadable(lazy(() => import('components/payments/settlement-accounts/index')));
const AdminRNPLView = Loadable(lazy(() => import('components/financing/adminIndex')));
const AdminSafeLeaseView = Loadable(lazy(() => import('components/financing/adminSafeLeaseIndex')));
const AdminShieldPlusIndex = Loadable(lazy(() => import('components/shieldplus/adminShieldPlusIndex')));
const TenantShieldPlusDetailsPage = Loadable(lazy(() => import('components/shieldplus/tenantShieldPlusDetailsView')));
const AdminClaimsIndex = Loadable(lazy(() => import('components/shieldplus/adminClaimsIndex')));
const TenantClaimsDetailsPage = Loadable(lazy(() => import('components/shieldplus/shieldPlusClaimDetails')));
const MoveOuts = Loadable(lazy(() => import('components/people/tenants/adminMoveOutView')));
const AdminSafeLeaseClaimsView = Loadable(lazy(() => import('components/financing/safeLeaseClaimsIndex')));
const SafeLeaseClaimForm = Loadable(lazy(() => import('components/financing/safeLeaseClaimForm')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'admin/dashboard',
            element: <DashboardDefault />
        },
        {
            path: 'admin/properties',
            element: <PropertyPage />
        },
        {
            path: 'admin/properties/create',
            element: <PropertyForm />
        },
        {
            path: 'admin/properties/:prop_id/edit/',
            element: <PropertyForm />
        },
        {
            path: 'admin/properties/:prop_id',
            element: <PropertyDetailsPage />
        },
        {
            path: 'admin/properties/:prop_id/add-units',
            element: <UnitsForm />
        },
        {
            path: 'admin/properties/:prop_id/units/:unit_id',
            element: <UnitDetails />
        },

        {
            path: 'admin/properties/:prop_id/tenants',
            element: <TenantsPage />
        },
        {
            path: 'admin/properties/:prop_id/requests',
            element: <Maintenance />
        },
        {
            path: 'admin/properties/:prop_id/applications',
            element: <ApplicationsPage />
        },
        {
            path: 'admin/units',
            element: <UnitsPage />
        },
        {
            path: 'admin/units/create',
            element: <UnitsForm />
        },
        {
            path: 'admin/units/:unit_id/update',
            element: <UnitsForm />
        },
        {
            path: 'admin/units/:unit_id/details',
            element: <UnitDetails />
        },
        {
            path: 'admin/tenants/create',
            element: <TenantForm />
        },
        {
            path: 'admin/tenants',
            element: <TenantsPage />
        },
        {
            path: 'admin/tenants/:tenant_id/details',
            element: <TenantsDetails />
        },
        {
            path: 'admin/tenants/:tenant_id/update',
            element: <TenantForm />
        },
        {
            path: 'admin/team',
            element: <TeamsPage />
        },
        {
            path: 'admin/team/create',
            element: <UserForm />
        },
        {
            path: 'admin/accounting',
            element: <AccountingPage />
        },
        {
            path: 'admin/accounting/settlement-accounts',
            element: <SettlementAccountsPage />
        },
        {
            path: 'admin/accounting/settlement-accounts/add',
            element: <SettlementAccountsForm />
        },
        {
            path: 'admin/reports',
            element: <ReportsPage />
        },
        {
            path: 'admin/people',
            element: <TenantsPage />
        },
        {
            path: 'admin/move-outs',
            element: <MoveOuts />
        },
        {
            path: 'admin/leases',
            element: <LeasePage />
        },
        {
            path: 'admin/leases/:lease_id',
            element: <LeaseDetails />
        },
        {
            path: 'admin/leases/create',
            element: <LeaseForm />
        },
        {
            path: 'admin/applications',
            element: <ApplicationPage />
        },
        {
            path: 'admin/applications/:id',
            element: <ApplicationDetailsPage />
        },
        {
            path: 'admin/maintenance',
            element: <MaintenancePage />
        },
        {
            path: 'rnpl',
            element: <AdminRNPLView />
        },
        {
            path: 'safelease',
            element: <AdminSafeLeaseView />
        },
        {
            path: 'safelease-claims',
            element: <AdminSafeLeaseClaimsView />
        },
        {
            path: 'safelease-claims/add',
            element: <SafeLeaseClaimForm />
        },
        {
            path: 'admin/shieldplus',
            element: <AdminShieldPlusIndex />
        },
        {
            path: 'admin/shieldplus/:id',
            element: <TenantShieldPlusDetailsPage />
        },
        {
            path: 'admin/claims',
            element: <AdminClaimsIndex />
        },
        {
            path: 'admin/claims/:id',
            element: <TenantClaimsDetailsPage />
        },
        {
            path: 'admin/maintenance/:id/details',
            element: <MaintenanceDetailsPage />
        },
        {
            path: 'admin/workorders',
            element: <MaintenancePage />
        },
        {
            path: 'admin/listings',
            element: <MarketingPage />
        },

        {
            path: 'admin/listings/:listing_id/details',
            element: <ListingDetails />
        },
        {
            path: 'admin/listings/create',
            element: <ListingForm />
        },
        {
            path: 'admin/listings/:listing_id/edit',
            element: <ListingForm />
        },
        {
            path: 'admin/communications',
            element: <CommunicationsPage />
        },
        {
            path: 'admin/communications/create',
            element: <MessageForm />
        },
        {
            path: 'admin/files',
            element: <DocumentPage />
        },
        {
            path: 'admin/settings',
            element: <SettingsPage />
        },
        {
            path: 'admin/help',
            element: <HelpPage />
        },
        {
            path: 'admin/user/account',
            element: <ProfilePage />
        },
        {
            path: 'admin/p',
            children: [
                {
                    path: 'admin/util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'admin/utils',
            children: [
                {
                    path: 'admin/util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'admin/utils',
            children: [
                {
                    path: 'admin/util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'admin/icons',
            children: [
                {
                    path: 'admin/tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'admin/icons',
            children: [
                {
                    path: 'admin/material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        }
    ]
};

export default AdminRoutes;
